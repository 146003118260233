<template>
    <div class="index">
        <!-- 导航轮播 -->
        <el-carousel class="banner" trigger="click" :autoplay="false" height="700px">
            <el-carousel-item v-for="(item, index) in bannerDatas" :key="index">
                <div class="bannerImg">
                    <img :src="require(`@/assets/images/index/banner${index + 1}.jpg`)" alt="bannner">
                    <div class="textbox">
                        <h2 class="animate__animated animate__slideInLeft">{{ item.title }}</h2>
                        <h3 class="animate__animated animate__slideInRight">{{ item.text }}</h3>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
      <!-- 圆周资讯 -->
      <div id="nav0" class="subhead animate__animated animate__slideInRight">
        <div class="container_title">
          <span class="chName">圆周资讯</span>
          <span class="enName">INFORMATION CENTER</span>
        </div>
      </div>
      <div class="container">
        <div class="news padUpDown animate__animated animate__fadeInLeft">
          <div class="contents">
            <el-carousel indicator-position="none" height="390px">
              <el-carousel-item v-for="(item, index) in valueNews" :key="index">
                <el-row :gutter="20" type="flex" justify="space-around" align="middle">
                  <el-col :xs="24" :sm="10" class="imgbox">
                    <img :class="`newsImg${index}`"
                         :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <div class="newCon">
                      <h3>
                        {{ item.imgName }}<br>
                        {{ item.imgTitle}}
                      </h3>
                      <div class="textDesc">
                        <p>
                          <div v-for="(segment, index) in item.imgText" :key="index">
                            <p>{{ segment }}</p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
        <div class="container">

            <div class="cando padUpDown animate__animated animate__fadeInLeft">
                <h3 class="title">圆周数科，为企业带来真正的价值！</h3>
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="6" :md="6" class="type_item" v-for="(item, index) in valueDatas" :key="index">
                        <el-card shadow="hover" class="card">
                            <div class="imgbox">
                                <img alt="" :src="require(`@/assets/images/index/cando${index + 1}.png`)">
                            </div>
                            <div class="textbox">
                                <h3 v-if="index == 0">{{ item.title }} <br>细节周到为“周”</h3>
                                <h3 v-else>{{ item.title }}</h3>
                            </div>

                            <p>{{ item.desc }}</p>

                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 产品线 -->
        <div id="nav1" class="subhead animate__animated animate__slideInRight">
            <div class="container_title">
                <span class="chName">产品线</span>
                <span class="enName">PRODUCT LINE</span>
            </div>
        </div>
        <div class="container">
            <div class="productlines padUpDown animate__animated animate__fadeInLeft">
                <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in productDatas" :key="index">
                        <div class="card" @click="goToDtails(index)">
                            <div class="imgbox"> <img alt=""
                                    :src="require(`@/assets/images/productlines/productlines${index + 1}.jpg`)">
                            </div>
                            <h3>{{ item.title }}</h3>
                            <p class="more" v-if="index < 4">了解更多</p>
                            <p v-for="(i, index) in item.child" :key="index">{{ i }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 解决方案 -->
        <div id="nav2" class="subhead animate__animated animate__slideInRight">
            <div class="container_title ">
                <span class="chName">解决方案</span>
                <span class="enName">SOLUTION</span>
            </div>
        </div>
        <div class="container">
            <div class="solution padUpDown animate__animated animate__fadeInUp">
                <el-row :gutter="20" class="type_wrap" v-for="(item, index) in solutionData" :key="index">
                    <el-col :xs="24" :sm="6">
                        <div class="imgbox">
                            <img alt="" :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="18" class="contbox">
                        <h3>{{ item.imgName }}</h3>
                        <p>{{ item.imgText }}</p>
                        <div class="personalInfo">
                            <img alt="" :src="require(`@/assets/images/index/robotics${index + 1}.png`)">
                            <span>{{ item.imgTitle }}</span>
                            <router-link
                                :to="index == 0 ? '/intelligentsolution' : index == 1 ? '/bigdatasolution' : ''">了解更多</router-link>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <!-- 合作伙伴 -->
        <div id="nav3" class="subhead animate__animated animate__slideInRight">
            <div class="container_title">
                <span class="chName">合作伙伴</span>
                <span class="enName">COOPERATIVE PARTNER</span>
            </div>
        </div>

        <div class="container">
            <div class="partners padUpDown animate__animated animate__fadeInLeft">
                <el-row :gutter="20" type="flex" justify="space-around" style="flex-wrap: wrap;">
                    <el-col :xs="11" :sm="6" v-for="index in HZHBImagesNews" :key="index">
                        <div class="card">
                          <img class="fixed-height "
                               :src="`https://www.yuanzhoudt.com/dev-api` + index.imgUrl">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>


        <div class="subban">
            <div class="container_title">
                <div class="con">
                    <h1 class="chName">数智化 ...</h1>
                    <span class="enName">交给我们 项目闭环 确保落地</span>
                </div>
            </div>
        </div>

        <!--  百度地图 -->
        <div id="nav4" class="container mapbox padUpDown animate__animated animate__slideInUp">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="20" class="map">
                    <baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler">

                        <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"
                            @click="infoWindowOpen">
                            <bm-info-window :show="show" title="江苏圆周数字科技有限公司" @close="infoWindowClose"
                                :offset="{ width: 15, height: 18, top: 0 }" @open="infoWindowOpen">地址：阿里云创新中心2楼207
                                <br>电话：0511-8560-8166
                            </bm-info-window>
                            <!-- <bm-label content="阿里云创新中心2楼207" :labelStyle="{
                                color: 'black',
                                fontSize: '14px',
                                borderColor: 'red',
                                padding: '5px',
                                borderRadius: 10
                            }" :offset="{ width: -60, height: 30 }" /> -->
                        </bm-marker>
                        <!-- //右上角缩放 -->
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <!--  <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
                            anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type> -->
                    </baidu-map>
                </el-col>
                <el-col :xs="12" :sm="4" class="mapInfo">
                    <i class="iconfont icon-zuoshuangjiantou"></i>
                    <div class="infobox">
                        <h1>联系我们</h1>
                        <span>CONTACT US</span>
                    </div>

                </el-col>
            </el-row>


        </div>

        <ul class="sideNav hidden-md-and-down" v-if="scrollMenu">
            <li @click="handleClick(index)" :class="{ 'active': isActive == index }" v-for="(item, index) in navlist">
                {{ item }}</li>
        </ul>
    </div>
</template>

<script>

import {
  selectHZHBImagesListAnoy,
  selectXWZXImagesListTOP3Anoy,
  selectJJFAImagesListAnoy,
  selectXWZXImagesListIsShow
} from "@/api/collaborators";

export default {
    name: "index",
    data() {
        return {
          valueNews:[],
          HZHBImagesNews:[],//查询的合作伙伴图片信息
            isActive: null,
            center: { lng: 0, lat: 0 },
            zoom: 3,
            map: null,
            BMap: null,
            show: true,

            bannerDatas: [
                { title: '十五年甲方实战 更注重数智化落地', text: '精准切入变革痛点 更有实力助推转型' },
                { title: '软件产品+技术服务', text: '全栈式信创能力助力企业关键业务系统平滑迁移' },
                { title: '打造智慧工厂 实现企业转型', text: '制造数字化-丰富实战与落地能力' },
                { title: '内外协同数字化办公', text: '统一门户 所有工作集成串连' }
            ],
            newsData: [
                {
                    title1: '“数实融合智引润州”',
                    title2: '——2023阿里云创峰会在润州区举办',
                    desc1: '当前，数字经济正日益成为拉动经济增长的新引擎。6月28日下午，“数字融合智引润州”主题高峰论坛在润州区举办，进一步搭建高端合作交流平台，探讨数字经济发展趋势，探索数字经济未来发展方略，构筑数字经济引领发展新优势，助力润州区数字经济产业实现高质量发展。',
                    desc2: '峰会由中共润州区峰会由中共润州区委员会、润州区人民政府、镇江市发展和改革委员会联合主办，官塘创新社区管委会、阿里云计算有限公司承办，圆周数字科技有限公司受邀列席，并在众多企业中脱颖而出，成为首批现场签约润州区数字化项目的企业。'
                },
                {
                    title1: '“润州区数字经济项目”集中签约',
                    title2: '热烈祝贺圆周数科首批登台签约',
                    desc1: '数字化转型是企业的必答题，而不是选择题。2023年6月28日，长三角（镇江）数字经济发展峰会在九华锦江国际酒店召开。润州区数字经济项目集中签约，江苏圆周数字科技有限公司首批登台签约，镇江市委、市区人民政府、市发改委、阿里云智能中国等领导见签。',
                    desc2: '会上，圆周数字科技云采购数字化项目正式落地润州，与众多企业一起为润州区数字经济产业发展、数字经济和实体经济深度融合注入新的活力。会上，润州区委领导表示：希望来润发展的企业携手前行，以数字为名，为产业赋能，与未来共生，抢抓风口，努力“出圈”，合力打造数字经济发展的产业高地、创新高地、价值高地。'
                },
                {
                    title1: '普元致力于加速企业数字化转型',
                    title2: '圆周数科成为普元解决方案合作伙伴',
                    desc1: '普元信息技术股份有限公司（股票代码“688118”）是国内软件基础平台（中间件）专业提供商，主要为金融、政务、能源、电信、制造业等行业，提供软件基础平台产品及相应技术服务。是国家规划布局内重点软件企业、国家企业技术中心、博士后科研工作站、国家高技术产业化示范工程单位。',
                    desc2: '普元信息基于全栈式信创中间件产品，构筑国产基础软件生态。2023年8月1日，圆周数科正式与普元达成战略合作，成为其优质的合作伙伴，面向数智未来，我们将普元携手，共同将高品质的技术产品和服务能力，助推助力客户管理模式和商业模式的变革，共同打造数字经济科技底座，成为领先的数字化转型使能企业。'
                },
                {
                    title1: '润州区阿里云创新中心落地镇江 ',
                    title2: '圆周数科受邀入驻',
                    desc1: '润州区是镇江市的主城区和行政中心所在地，是镇江政治中心、商业中心和文化中心的核心承载区，人口24万，面积113平方公里，是“山水镇江”“人文镇江”“生态镇江”的缩影。在2021年镇江市第八次党代会报告提出，“围绕‘一体、两冀、三带、多片区’总体布局，有序谋划打造九大重点片区”。官塘创新社区位于九大片区之首，以发展智能制造和数字服务两大产业为方向。',
                    desc2: '阿里云创新中心（属于官塘创新社区）是阿里云基于互联网、大数据、云计算的科技创新企业服务平台。阿里云创新中心·镇江是镇江市政府、阿里云计算有限公司等联合发起的创新中心，2021年5月11日，江苏圆周数字科技有限公司强势入驻，为创新型产业科技赋能、人才赋能、流量赋能、生态赋能。'

                }
            ],
            // solutionData: [
            //     {
            //         title: '智能制造',
            //         desc: '是数字化、信息化、自动化和智能化技术有机融合的集大成者，圆周数科主打MES、WMS、APS智能制造核心应用产品及实施落地。',
            //         text: '成为首选信赖的产业数字化服务领跑商'
            //     },
            //     {
            //         title: '大数据分析',
            //         desc: '公司已成功实施多个行业，拥有精良的服务团队，精抓客户分析诉讼，导师级引导并帮助客户实施落地大数据分析，让数据反哺业务，提升企业的科学决策力。',
            //         text: '创造超越企业预期目标的价值为其发展赋能，使组织更高效、更智慧。'
            //     },
            //     {
            //         title: '定制开发',
            //         desc: '包含网站、公众号、小程序、二次开发、企业信息系统开发、普元低代码平台开发、JAVA开发等，全方位满足企业个性化需求。',
            //         text: '诚信、协同、思维、专注、改善。'
            //     },
            //     {
            //         title: '基础软件平台',
            //         desc: '普元ESB企业服务总线——重点解决系统间点对点集成导致的异常排查困难、访问安全性弱、维护成本高的问题；普元主数据平台——重点解决同一基础数据在不同系统间反复录入且易出现不一致的情况；普元低代码开发平台——重点解决企业业务系统繁多，技术架构不一造成的管理困难，开发成本高的困惑。',
            //         text: '精抓痛点，卓越方案，确保落地。'
            //     },
            // ],
            productDatas: [
                { title: '协同办公', child: ['即时通讯', 'OA智慧办公', '电子签章', '企业邮箱'] },
                { title: '大数据', child: ['BI商务智能'] },
                { title: '基础软件', child: ['低代码开发平台', 'ESB企业服务总线', '主数据管理平台', 'BPM流程引擎'] },
                { title: '智能制造', child: ['MES生产执行系统', 'WMS仓储管理系统', 'APS智能排程', '工业仿真'] },
                { title: '智慧人事', child: ['组织人事', '智慧招聘', '智慧薪酬', '智能绩效', '在线培训'] },
                { title: '供应链', child: ['智慧营销', '电子采购平台'] },
                { title: '云服务', child: ['华为云', '阿里云'] },
                { title: '定制服务', child: ['信息系统定制开发', '小程序', 'APP', '网站', '公众号'] },
            ],
            valueDatas: [
                { title: '项目闭环为“圆”', desc: '更注重项目的闭环，即流程的无缝衔接和完备，形成一个高效的无死角的运转圈。' },
                { title: '完备的资源保障体系', desc: '整合行业内顶尖解决方案，创造超越企业预期目标的价值。' },
                { title: '强大的技术团队', desc: '公司开发团队成员汇集了原大型数百亿集团CIO、互联网公司CEO、互联网公司CTO、互联网公司资深顾问等行业顶端人才。' },
                { title: '专人实施 确保落地', desc: '精抓痛点、卓越方案、确保落地。' },
            ],
            navlist: ['圆周资讯','产品线', '解决方案', '合作伙伴',  '联系我们'],
            scrollMenu: false
        };
    },
    created() { // 此处true需要加上，不加滚动事件可能绑定不成功
        window.addEventListener("scroll", this.hanScroll, true);
    },
    mounted() {
        window.addEventListener("scroll", () => {
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            this.scrollMenu = scrollTop > 300;
        });
        this.getHZHBImagesList()
        this.getXWZXImagesListTOP3()
        this.selectJJFAImagesListAnoy()
    },
    destroyed() {
        window.addEventListener("scroll", this.hanScroll, false);
    },
    methods: {
      /** 获取合作伙伴的图片信息*/
      getHZHBImagesList() {
        selectHZHBImagesListAnoy().then(response => {
          if (response.rows){
            this.HZHBImagesNews = response.rows
          }
        })
      },
        infoWindowOpen() {
            this.show = true
        },
        infoWindowClose() {
            this.show = false
        },
        // 切换tabs
        handleClick(val) {
            this.isActive = val
            var el = document.querySelector(`#nav${val}`);
            window.scroll({
                top: el.offsetTop - 100,
                behavior: "smooth" // 平滑过渡
            });
        },
        hanScroll() {
            let scrolltop =
                document.documentElement.scrollTop + 200 || document.body.scrollTop + 200;
            for (let i = 0; i < this.navlist.length; i++) {
                var prev = document.querySelector(`#nav${i}`);
                /*   console.log(prev, 'prev====') */
                if (prev && i == 0 && scrolltop < prev.offsetTop) {
                    this.isActive = null
                } else if (prev && scrolltop > prev.offsetTop) {
                    this.isActive = i
                }
            }
        },

        goToDtails(index) {
            if (index < 4) {
                this.$router.push({
                    path: index == 0 ? "/basicsoftware" : index == 1 ? '/smartmanufacturing' : index == 2 ? '/bigdata' : 'collaborativeoffice',
                });
            }
        },
        handler({ BMap, map }) {
            this.map = map
            this.BMap = BMap
            this.center.lng = 119.41517
            this.center.lat = 32.191057
            this.zoom = 13
        },
      getXWZXImagesListTOP3() {
        selectXWZXImagesListIsShow().then(response => {
            if (response.rows){
              this.valueNews = response.rows.map(item => {
                item.imgText = item.imgText.split('。').map(segment => segment.trim());
                return item;
              });
            }
        })
      },
      selectJJFAImagesListAnoy() {
        selectJJFAImagesListAnoy().then(response => {
          if (response.rows){
            this.solutionData = response.rows
          }
        })
      },
    },
};
</script>

<style lang="scss" scoped>
.index {
    overflow: scroll;
}
.subhead {
    height: 60px;
    line-height: 60px;
    font-size: 12px;
    font-weight: 400;
    color: #666;
    background: #d3dce6;

    .chName {
        font-size: 26px;
        font-weight: bolder;
        color: #333;
        margin-right: 10px;
    }
}

.subban {
    height: 700px;
    padding: 0 120px;
    position: relative;
    background: #000;
    color: #fff;
    line-height: 60px;
    background: url("@/assets/images/index/intelligence.jpg") no-repeat;
    background-size: cover;

    .con {
        position: absolute;
        bottom: 100px;
        font-size: 38px;

        .chName {
            font-size: 60px;
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
}

.banner {
    .bannerImg {
        height: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            display: block;
        }

        .textbox {
            position: absolute;
            top: 30%;
            left: 10%;

            h2 {
                font-size: 50px;
                color: #04aaeb;
            }

            h3 {
                font-size: 30px;
                color: #fff;
            }
        }
    }
}



/* 能做什么 */
.cando {
    h2 {
        font-size: 38px;
        margin: 20px 0;
    }

    .desc {
        color: #333;
        font-size: 14px;
        line-height: 25px;
    }

    .title {
        font-size: 24px;
        color: #04aaeb;
        text-align: center;
        font-weight: 500;
        letter-spacing: 5px;
        margin: 30px auto;
    }


    .card {

        min-height: 430px;
        text-align: center;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;

        .imgbox {

            width: 100%;
            /*   height: 240px; */
            overflow: hidden;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
                display: inline-block;
                vertical-align: middle;
                transition: All 0.4s ease-in-out;
                -webkit-transition: All 0.4s ease-in-out;
                -moz-transition: All 0.4s ease-in-out;
                -o-transition: All 0.4s ease-in-out;
            }
        }

        .textbox {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0 0;

            h3 {
                color: #333;
                font-size: 16px;
            }
        }



        p {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .card:hover {
        -webkit-transform: translate(0, -10px);
        transform: translate(0, -10px);

        img {
            transform: scale(1.2);

        }
    }
}

//产品线
.productlines {
    .type_wrap {
        .type_item {
            .card {
                height: 380px;
                text-align: center;
                width: 100%;
                margin-bottom: 20px;
                transition: all .3s ease-in-out;
                border-radius: 8px;
                border: 1px solid #f1f1f1;

                .imgbox {
                    width: 100%;
                    height: 180px;
                    margin-bottom: 30px;
                    overflow: hidden;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;

                    img {
                        width: 100%;
                        height: 100%;
                        display: inline-block;
                        vertical-align: middle;
                        transition: All 0.4s ease-in-out;
                        -webkit-transition: All 0.4s ease-in-out;
                        -moz-transition: All 0.4s ease-in-out;
                        -o-transition: All 0.4s ease-in-out;
                    }
                }

                h3 {
                    color: #333;
                    font-size: 22px;
                    margin-bottom: 10px;
                    margin-top: 0;
                }

                .more {
                    margin-bottom: 5px;
                    color: red;
                    cursor: pointer;
                }


                p {
                    font-size: 12px;
                    line-height: 20px;
                    margin: auto 20px;
                }
            }

            .card:hover {
                box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                transform: translate(0, -5px);

                img {
                    transform: scale(1.2);
                }
            }
        }
    }
}


/* 解决方案 */
.solution {

    .type_wrap {

        margin-bottom: 30px;
        background: #f7f9fb;
        padding: 20px;

        .imgbox {
            overflow: hidden;
            height: 200px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                display: block;
                transition: all 0.6s;
            }
        }

        .contbox {
            padding: 20px;
            position: relative;
            height: 200px;

            h3 {
                color: #333;
                font-size: 20px;
                margin: 0 0 20px;
            }

            .personalInfo {
                position: absolute;
                bottom: 0;
                width: 100%;

                img {
                    width: 30px;
                    display: inline-block;
                    vertical-align: middle;
                }

                span {
                    font-size: 12px;
                    line-height: 20px;
                    display: inline-block;
                    margin-left: 10px;
                }

                a {
                    float: right;
                    color: #04aaeb;
                }
            }
        }
    }

    .type_wrap:hover {
        .imgbox img {
            /*  animation: rotate 1s forwards;
      -webkit-animation: rotate 1s forwards; */
            transform: scale(1.2);
        }
    }
}

/* //合作伙伴 */
.partners {
    padding: 30px 10px;

    .card {
        height: 100px;
        position: relative;

        img {
          max-width: 180px; /* 最大宽度 */
          width: 100%;      /* 宽度按比例缩放 */
          height: auto;     /* 高度按比例缩放 */
          margin: 0 auto;   /* 水平居中 */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

        }
      .fixed-height {
        height: 80px; /* 固定高度 */
        max-width: 180px; /* 最大宽度 */
        width: auto;   /* 宽度按比例缩放 */
        object-fit: contain; /* 按比例缩放并覆盖容器 */
      }



        .imgSize7,
        .imgSize5 {
            width: 80%;
        }

        .imgSize4,
        .imgSize9 {
            width: 60%;
        }

        .imgSize2,
        .imgSize10,
        .imgSize11,
        .imgSize12 {
            width: 50%;
        }

        .imgSize3,
        .imgSize6,
        .imgSize8 {
            width: 40%;
        }

    }
}

/* //圆周资讯 */
.news {
    .contents {
        padding: 20px;
        background: #f7f9fb;

        .imgbox {
            height: 360px;
            display: flex;
            align-items: center;

            img {
                border-radius: 20px;
                margin: 0 auto;
                max-width: 100%;
            }
        }

        .newCon {
            h3 {
                color: #333;
                font-size: 24px;
                margin: 20px 0;
                text-align: center;
                line-height: 36px;
            }

            .textDesc {
                /*   max-height: 250px;
                overflow-y: auto; */

                p {
                    text-indent: 2em;
                    font-size: 14px;
                    line-height: 25px;
                    color: #333;
                }
            }


        }

        .el-carousel__item {
            background-color: unset !important;
        }
    }

}


.mapbox {
    .map {
        height: 500px;

        .bm-view {
            width: 100%;
            height: 100%;
        }
    }

    .mapInfo {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            float: right;
            font-size: 12px;
            margin-top: 10px;
        }

        .infobox {
            h1 {
                letter-spacing: 3px;
                font-size: 26px;
                font-weight: 500;
            }
        }
    }
}
</style>
