import request from '@/utils/request'

//查询新闻信息
export function selectXWZXListAnoy(query) {
  return request({
    url: '/yzsk/webwangeditor/selectXWZXListAnoy',
    method: 'get',
    params: query
  })
}


