import request from '@/utils/request'

//网站查询工作职位
export function selectWebJobWithTabsResultAnoy(query) {
  return request({
    url: '/yzsk/webjob/selectWebJobWithTabsResultAnoy',
    method: 'get',
    params: query
  })
}
