<template>
    <div class="joinUs">
        <div class="banner">
            <div class="textbox">
                <h2 class="animate__animated animate__slideInLeft">加入我们</h2>
                <h3 class="animate__animated animate__slideInRight">价值感来源于肯定 归属感来源于被爱</h3>
            </div>
        </div>
        <!-- 联系我们 -->

        <div class="container">
            <div class="contactUsCon padUpDown animate__animated animate__slideInUp">

                <div class="textDesc">
                    <h2>加入我们</h2>
                </div>
                <h3 class="hot"> <i class="icon-icon-test iconfont"></i> 热招职位</h3>

                <!-- MES实施工程师 -->
                <div class="jobList" v-for="(item, index) in workList" :key="index">
                    <div class="jobItem" @click="showItemCon(item)">
                        <span class="jonName">{{ item.jobName }} <i>【{{item.jobAddress}}】</i><i class="num">{{ item.jobMoney
                        }}</i></span>
                        <span class="address"> <i>{{ item.jobYear }}</i> <i>{{item.jobEduName}}</i> </span>
                        <div class="tabs">
                            <span v-for="(row, i) in item.jobTags" :key="i">
                                <i :class="`bkcol${i}`">{{ row }}</i>
                            </span>
                            <el-button type="primary" icon="el-icon-s-comment" size="mini" round class="contactsUsbtn"
                                @click.stop="showDialogVisible = true">立即沟通</el-button>
                        </div>
                    </div>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                              <ul v-for="(i1, in1) in item.jobResponsibilitiesList" :key="in1">
                                <li>{{i1}}；</li>
                              </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                              <ul v-for="(i1, in1) in item.jobReqs">
                                <li>{{ i1 }}；</li>
                              </ul>
                            </div>
                        </el-col>
                    </el-row>
<!--                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 1 && item.show">-->
<!--                        <el-col :xs="24" :sm="11">-->
<!--                            <div class="duty">-->
<!--                                <h2>岗位职责：</h2>-->
<!--                                <ul>-->
<!--                                    <li>负责为客户提供专业的WMS顾问和实施服务；</li>-->
<!--                                    <li>负责WMS项目前期的客户需求分析，售前解决方案制定；</li>-->
<!--                                    <li>负责对客户进行项目实施培训并支持后续的实施应用；</li>-->
<!--                                    <li>负责完成WMS系统配置和测试工作；</li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                        <el-col :xs="24" :sm="11">-->
<!--                            <div class="duty">-->
<!--                                <h2>任职要求：</h2>-->
<!--                                <ul>-->
<!--                                    <li>计算机相关专业，本科学历，28-45岁，3年以上WMS系统实施经验；</li>-->
<!--                                    <li>熟悉MES/WMS/TMS/OMS/CRM/ERP系统的体系结构和重要相关表结构，</li>-->
<!--                                    <li>精通SQL、Oracle数据库开发；</li>-->
<!--                                    <li>有开发经验者优先。</li>-->
<!--                                    <li>有高度责任心,做事认真负责；吃苦耐劳,能够积极应对及解决项目过程中的各类突发事件；</li>-->
<!--                                    <li>有仓储管理经验者优先，具备良好的沟通能力和综合分析能力；</li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                </div>

            </div>
        </div>
        <el-dialog title="" :visible.sync="showDialogVisible" center>
            <div class="textDesc">
                <h2>联系我们</h2>
                <div class="caveat">
                    <h3> <i class="el-icon-s-home"></i>江苏圆周数字科技有限公司</h3>
                    <p> <i class="el-icon-location-information"> </i>江苏省镇江市润州区茶砚山路106号阿里云创新中心2楼207</p>
                    <p> <i class="el-icon-phone-outline"> </i>08：30-18：30 —— <a href="tel:0511 8560 8166">0511
                            8560 8166</a></p>
                    <p> <i class="el-icon-mobile-phone"> </i>24H 来电 —— <a href="tel:182 6064 3153">182 6064
                            3153</a></p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {selectWebJobWithTabsResultAnoy} from '@/api/webjob'
import {log, LOG2E} from "mathjs";
export default {
    name: "joinUs",
    data() {
        return {
            showDialogVisible: false,
            form: {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            workList: [],
            rules: {
                name: [{
                    required: true, message: '姓名不能为空', trigger: 'blur'
                }],
                phone: [{
                    required: true, message: '电话不能为空', trigger: 'blur'
                }]
            }
        };
    },
    created() {
      this.getWebJobList()
    },
    mounted() {

    },
  methods: {
        showItemCon(item) {
          item.show = !item.show
        },
        getWebJobList() {
            selectWebJobWithTabsResultAnoy({'status':'01'}).then(response => {
                if (response.code && response.code == 200){
                    this.workList = response.rows
                    if (this.workList){
                      this.workList = response.rows.map(item => ({
                        ...item,
                        jobReqs: item.jobReq.split(/；|;/).map(req => req.trim()).filter(req => req !== ''),
                        jobResponsibilitiesList: item.jobResponsibilities.split(/；|;/).map(responsibility => responsibility.trim()).filter(responsibility => responsibility !== ''),
                        show: false
                      }));
                    }
                }
            })
        }

    },

};
</script>

<style lang="scss" scoped>
.joinUs {
    .banner {
        height: 700px;
        background: url("@/assets/images/joinus/banners.jpg") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;




    }

    .textDesc {
        h2 {
            text-align: center;
            margin-bottom: 30px;
        }

        .caveat {
            margin-bottom: 20px;
            border: 1px solid #f5f5f5;
            padding: 10px 20px;

            i {
                margin-right: 5px;
                font-size: 20px;
                color: #04aaeb;
            }

            h3 {
                font-size: 15px;
                margin-bottom: 10px;
                color: #000;
            }

            p {
                line-height: 30px;
                font-size: 14px;
                color: #000;

                a {
                    text-decoration: none;
                    color: #000;
                }

                a:hover {
                    color: #e6a23c;
                }
            }
        }

    }

    .hot {
        line-height: 60px;

        i {
            color: red;
            font-size: 30px;
        }
    }

    .jobList {

        margin-bottom: 30px;
        border: 1px solid #efefef;
        border-radius: 5px;
        position: relative;
        transition: all .3s ease-in-out;

        .jobItem {
            padding: 20px 30px;
            cursor: pointer;

            .jonName {
                font-size: 18px;
                color: #000;

                i {
                    font-style: normal;
                    font-size: 16px;
                    color: #04aaeb;
                }

                .num {
                    margin-left: 5px;
                    color: #f9b800;
                    font-size: 14px;
                }
            }

            .tabs {
                padding-top: 10px;

                span {
                    display: inline-block;
                    padding: 0 10px;
                    border-right: 1px solid #ccc;

                    i {

                        font-size: 12px;
                        padding: 3px 10px;
                        color: #fff;
                        border-radius: 5px;
                        font-style: normal;
                        border: 1px solid #fff;

                    }

                    .bkcol0,
                    .bkcol5 {
                        /*  background: rgb(144, 147, 153, 0.6); */
                        color: #909399;
                        background: #f4f4f5;
                        border-color: #d3d4d6;
                    }

                    .bkcol1,
                    .bkcol6 {
                        /*  background: rgb(64, 158, 255, 0.6); */
                        color: #409eff;
                        background: #ecf5ff;
                        border-color: #b3d8ff;
                    }

                    .bkcol2,
                    .bkcol7 {
                        /*  background: rgb(103, 194, 58, 0.6); */
                        color: #67c23a;
                        background: #f0f9eb;
                        border-color: #c2e7b0;
                    }

                    .bkcol3,
                    .bkcol8 {
                        /*     background: rgb(230, 162, 60, 0.6); */
                        color: #e6a23c;
                        background: #fdf6ec;
                        border-color: #f5dab1;
                    }

                    .bkcol4,
                    .bkcol9 {
                        /*      background: rgb(245, 108, 108, 0.6); */
                        color: #f56c6c;
                        background: #fef0f0;
                        border-color: #fbc4c4;
                    }


                }

                span:first-child {
                    padding-left: 0;

                }

                span:last-of-type {
                    border-right: 1px solid #fff;
                }

                .contactsUsbtn {
                    margin-left: 30px;
                    float: right;
                }


            }

            .address {
                float: right;

                i {
                    font-size: 16px;
                    color: #04aaeb;
                    font-style: normal;
                    display: inline-block;
                    margin-left: 10px;
                }

            }
        }

        .joinCon {
            border-top: 1px solid #f1f1f1;
            padding: 20px 20px 30px 50px;
            flex-wrap: wrap;

            .duty {
                font-size: 14px;

                h2 {
                    margin: 10px 0;
                    font-size: 16px;
                }

                ul {
                    list-style-type: none;

                    li {
                        line-height: 25px;
                        letter-spacing: 1px;
                        color: #a3a3a3;
                    }
                }

            }
        }

    }

    .jobList:hover {
        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
    }
}
</style>
