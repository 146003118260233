import request from '@/utils/request'

//批量查询合作伙伴图片信息
export function selectHZHBImagesListAnoy(query) {
  return request({
    url: '/yzsk/webimages/selectHZHBImagesListAnoy',
    method: 'get',
    params: query
  })
}
//批量查询新闻中心图片信息
export function selectXWZXImagesListAnoy(query) {
  return request({
    url: '/yzsk/webimages/selectXWZXImagesListAnoy',
    method: 'get',
    params: query
  })
}
//查询新闻中心图片信息前三
export function selectXWZXImagesListTOP3Anoy(query) {
  return request({
    url: '/yzsk/webimages/selectXWZXImagesListTOP3Anoy',
    method: 'get',
    params: query
  })
}
//查询新闻中心图片是否轮播
export function selectXWZXImagesListIsShow(query) {
  return request({
    url: '/yzsk/webimages/selectXWZXImagesListIsShow',
    method: 'get',
    params: query
  })
}
//查询解决方案类型信息
export function selectJJFAImagesListAnoy(query) {
  return request({
    url: '/yzsk/webimages/selectJJFAImagesListAnoy',
    method: 'get',
    params: query
  })
}
