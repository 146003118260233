import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import index from '@/views/index.vue'
import productlines from '@/views/productlines.vue'
import aboutUs from '@/views/aboutUs.vue'
import intelligentsolution from '@/views/intelligentsolution.vue'
import bigdatasolution from '@/views/bigdatasolution.vue'
import collaborators from '@/views/collaborators.vue'
import joinUs from '@/views/joinUs.vue'
import newscenter from '@/views/newscenter.vue'
import newscenterDetails from '@/views/newscenterDetails.vue'
import basicsoftware from '@/views/basicsoftware.vue'
import smartmanufacturing from '@/views/smartmanufacturing.vue'
import bigdata from '@/views/bigdata.vue'
import collaborativeoffice from '@/views/collaborativeoffice.vue'


export default new Router({
    mode: 'hash',
    routes: [
        // 首页
        { path: "/", name: 'index', component: index },
        { path: "/productlines", name: 'productlines', component: productlines },
        { path: "/aboutUs", name: 'aboutUs', component: aboutUs, },
        { path: "/intelligentsolution", name: 'intelligentsolution', component: intelligentsolution },
        { path: "/bigdatasolution", name: 'bigdatasolution', component: bigdatasolution },

        { path: "/collaborators", name: 'collaborators', component: collaborators },
        {   path: "/newscenter",
            name: 'newscenter',
            component: newscenter,
            meta:{title:'新闻中心'}
        },
        {
            path: '/newscenter/newscenterDetails',
            name: 'newscenterDetails',
            component: newscenterDetails,
            meta:{title:'文章详情'}
        },
        { path: "/joinUs", name: 'joinUs', component: joinUs },
        { path: "/basicsoftware", name: 'basicsoftware', component: basicsoftware },
        { path: "/smartmanufacturing", name: 'smartmanufacturing', component: smartmanufacturing },
        { path: "/bigdata", name: 'bigdata', component: bigdata },
        { path: "/collaborativeoffice", name: 'collaborativeoffice', component: collaborativeoffice },

    ],
})
