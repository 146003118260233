<template>
    <div class="basicsoftware">
        <div class="banner">
            <h2 class="animate__animated animate__slideInLeft">基础软件</h2>
        </div>
        <div class="productlines  padUpDown" id="tabsScroll">
            <!-- <div class="container">
                <breadcrumb text="基础软件" />
            </div> -->

            <el-tabs v-model="activeName" :class="{ isScroll: scroll }" @tab-click="handleClick">
                <el-tab-pane label="低代码开发平台" name="first">
                    <div class="lowCode">
                        <h2>场景化的数字化应用</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                在企业数字化转型迈向纵深的过程中，企业越来越多地围绕数字化进行产品和服务的快速研发和敏捷创新，并通过数字化应用从海量数据中生成数据洞察，提升运营效率，持续创造出更大的商业价值。普元低代码数字化应用平台基于数字化运营理念，围绕数字驱动的创新业务快速实现而展开，帮助企业实现模型驱动、多端适配、流程融合的低代码开发，通过抽象出用户、账户、产品等不同维度的业务对象，融合主数据等数据资产形成的统一数据模型等方式，满足企业级应用快速变化的动态需求，构建数字化运营体系对用户在应用使用过程中的数据进行采集、分析，支持数字化应用持续演进，在数字化转型过程中发挥关键作用。同时，平台还满足资源隔离、动态扩容、数据连接等云化部署要求。
                            </div>
                            <div class="title">
                                <div class="container_title">产品特点</div>
                            </div>
                            <div class="container padUpDown">
                                <div class="specific">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="12" class="type_item" v-for="(item, index) in productDatas"
                                            :key="index">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>{{ item.title }}</h3>
                                                    <p>{{ item.desc }}</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>
                                </div>

                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/lowCodespec.png" alt="">
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="keyValue container padUpDown"> <el-row :gutter="20"
                                    class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="12" class="type_item">
                                        <div class="card">

                                            <h3><i class="iconfont icon-zuanshi"></i> 商业价值：贯通商业链路，加速企业数字化转型</h3>
                                            <p>敏捷——提升需求响应速度</p>
                                            <p>降本——降低经营成本</p>
                                            <p>增效——优化服务效率</p>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" class="type_item">
                                        <div class="card">

                                            <h3><i class="iconfont icon-yewu-xianxing"></i> 业务价值：支撑业务创新，加速业务有效落地
                                            </h3>
                                            <p>创新——迅速创建新应用，辅助业务流程数字化创新</p>
                                            <p>高效——高效优化新业务，提高跨部门业务的协同效率</p>
                                            <p>精益——多维度多视角多指标的数据分析，为业务持续迭代提供数字化支撑</p>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" class="type_item">
                                        <div class="card">

                                            <h3><i class="iconfont icon-tubiaozhizuomoban-115"></i>
                                                技术价值：赋能科技创新，推动业务转型</h3>
                                            <p>能力——提升技术能力复用性</p>
                                            <p>实效——增加技术创新实效性</p>
                                            <p>转型——降低业务转型成本</p>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" class="type_item">
                                        <div class="card">
                                            <h3><i class="iconfont icon-rencaishuangxuanhui"></i>
                                                人才价值：帮助企业突破数字化人才培养瓶颈</h3>
                                            <p>助力——助力业务人员对新兴技术的理解</p>
                                            <p>培养——提升企业对复合人才的培养</p>
                                            <p>管理——降低企业对IT人力费用的支出</p>
                                        </div>
                                    </el-col>

                                </el-row></div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="主数据管理平台" name="second">
                    <div class="lowCode">
                        <h2>智能化的数据中台</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    普元主数据管理平台为企业各类主数据标准、数据建模、数据全生命周期管理、数据质量校验、数据清洗、数据服务、流程审核、数据集成等提供一体化平台化支撑，确保企业主数据的及时性、完整性有效性、准确性、一致性，持续提升数据质量和主数据应用能力；同时，推动企业信息化以及数字化革新，支撑数据化决策和数字化运营。
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/overviewDesc.png" alt="">
                                </div>

                                <h2 class="center_title">痛点</h2>
                                <div class="specific ">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="12" class="type_item">
                                            <el-row :gutter="20" class="card mainData">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>信息系统各自为政，形成信息孤岛</h3>
                                                    <p>基础数据不统一，缺少数据管理标准</p>
                                                    <p>系统缺乏横向和纵向集成，只能满足部门需求</p>
                                                    <p>信息和数据割裂，缺乏完整性、准确性和及时性</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" class="type_item">
                                            <el-row :gutter="20" class="card mainData">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>一物多码，造成资金占用</h3>
                                                    <p>缺乏统一标准，出现一物多码现象，不利于科学的采购决策，导致库存积压。</p>
                                                    <p>不利于系统间的集成、数据分析</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>
                                </div>
                                <h2 class="center_title">主数据管理解决的问题</h2>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/overSolution2.png" alt="">
                                </div>
                                <h2 class="center_title">主数据带来的变化</h2>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/overChange.png" alt="">
                                </div>
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container ">
                                <div class="masterdataSpecific padUpDown">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="12" :sm="6" class="type_item" v-for="(item, index) in productTags"
                                            :key="index">
                                            <div class="card">
                                                <div class="imgbox">
                                                    <i :class="['iconfont', item.icon]"></i>
                                                </div>
                                                <h3>{{ item.title }}</h3>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品价值</div>
                            </div>
                            <div class="container padUpDown">
                                <p>主数据管理平台建设工作的目标是从业务协同的角度出发开展信息资源规划，制定企业级数据标准、集成标准、数据管理规范，加强数据资源的整合集成、服务管理和安全监督，建设形成统一的主数据共享交换中心，从而实现主数据资源的高度共享与深度应用。为企业IT系统的建设提供数据基础、管理工具、落实手段、分析服务和安全防护等关键支撑，打通企业全业务领域的业务系统数据交互通道，实现主数据集成与共享，为不断提升数据资源综合应用效益提供良性数据生态环境。
                                </p>
                                <p style="margin-top: 20px;">
                                    主数据管理平台的建设是提升企业信息化的前提，通过信息化的手段能够解决企业生产、管理各阶段的数据支撑问题。所以实施主数据管理平台，为高效的业务流转、数据共享和决策支持提供强有力的数据支撑。
                                </p>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="ESB企业服务总线" name="third">
                    <div class="lowCode">
                        <h2>性能卓越的企业服务总线</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    ESB服务日调用达到网商级服务调用处理能力，依托普元自主创新技术，高安全高可用。
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/esbDesc.png" alt="">
                                </div>

                                <h2 class="center_title">痛点</h2>
                                <div class="specific ">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="12" class="type_item">
                                            <el-row :gutter="20" class="card esb">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>架构复杂</h3>
                                                    <p>点对点的系统交互，使得系统耦合性高，后续系统的升级影响的面太广，系统发展的复杂度提高。</p>

                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" class="type_item">
                                            <el-row :gutter="20" class="card esb">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>开发集成难度高</h3>
                                                    <p>多系统集成涉及异构系统多种协议和多种语言的场景，集成开发需要掌握多种集成方式，集成难度高。</p>

                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" class="type_item">
                                            <el-row :gutter="20" class="card esb">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>运维难度大</h3>
                                                    <p>非功能性需求被忽视，接口的报文监控、健康状况、和异常报警没有系统性落地，运维难度高。</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" class="type_item">
                                            <el-row :gutter="20" class="card esb">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>管理混乱 资产流失</h3>
                                                    <p>系统接口的开发纯黑盒操作，没有形成可管理的IT资产，便于未来接口能力的重用，容易造成重复投资。</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>
                                </div>
                                <h2 class="center_title">ESB服务总线带来的优势</h2>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/esbAdvantage.png" alt="">
                                </div>
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container ">
                                <div class="esbSpecific padUpDown">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in specificDatas"
                                            :key="index">
                                            <el-card shadow="hover" class="card">
                                                <div class="imgbox">
                                                    <i :class="[item.icon, 'iconfont']"></i>
                                                </div>
                                                <h3>{{ item.title }}</h3>
                                                <p>{{ item.desc }}</p>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品价值</div>
                            </div>
                            <div class="container productValue padUpDown">
                                <p class="overviewdesc">
                                    PrimetonESB提供各种服务血缘管理和运行跟踪机制，使得IT部门能够更好地掌控服务集成的状况，还提供了及时的SLA状态通知机制，使得IT部门的关键人员能够更加及时、自动化地了解关键部件的运行状况，并可以根据状况采取不同的运维和优化手段，从而大大提高业务部门的满意度，提高IT部门的KPI。
                                </p>
                                <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="12" class="type_item" v-for="(item, index) in valueDatas"
                                        :key="index">
                                        <el-row :gutter="20" class="card">
                                            <el-col :span="1" class="imgbox">
                                                <i :class="[item.icon, 'iconfont']"></i>
                                            </el-col>
                                            <el-col :span="23" class="personalInfo">
                                                <div class="contbox">
                                                    <h3>{{ item.title }}</h3>
                                                    <p>{{ item.desc }}</p>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>


                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="BPM流程引擎" name="fourth">
                    <div class="lowCode">
                        <h2>流程应用一站式解决</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    普元数字化流程应用中心是基于普元多年的企业级业务应用、流程体系建设经验打造的面向大中型企业的“流程应用一站式解决方案”，整个融合了“流程梳理、流程建模、流程开发、流程集成、流程应用、运行监控、升级迭代”等业务流程全生命周期的能力。
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/bpmDesc.png" alt="">
                                </div>
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container padUpDown">
                                <p>
                                    普元数字化流程应用中心集成了普元高开平台、低开平台、流程引擎、集成平台、流程门户等多款成熟产品的能力，具备成熟度高、稳定性好、扩展性强、功能完善的特点，同时具备高低开流程建设能力，兼顾企业各类复杂业务流程场景应用。
                                </p>
                            </div>

                            <div class="title">
                                <div class="container_title">产品价值</div>
                            </div>
                            <div class="container">
                                <div class="esbSpecific padUpDown">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-card shadow="hover" class="card cardBpm">
                                                <div class="imgbox">
                                                    <i class="iconfont icon-jieruliucheng"></i>
                                                </div>
                                                <p style="margin-top: 30px;text-align: left;">普元数字化流程应用中心提供多种业
                                                    务场景的流程建模开发，支持业务流 程建模、操作流程建模、交易流程建 模，满足高低开融合、快速低开、服 务流程编排场景的流程开发。
                                                </p>
                                            </el-card>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-card shadow="hover" class="card cardBpm">
                                                <div class="imgbox">
                                                    <i class="iconfont icon-xitongjiaoseyingyong"></i>
                                                </div>
                                                <p style="margin-top: 30px;text-align: left;">普元数字化流程应用中心提供完善的
                                                    应用管控平台，支持应用应用发布、 上架\下架、版本管控、访问配置、 运行监控等相关能力，给应用应用运 维团队提供便利。</p>
                                            </el-card>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-card shadow="hover" class="card cardBpm">
                                                <div class="imgbox">
                                                    <i class="iconfont icon-Tireroll"></i>
                                                </div>
                                                <p style="margin-top: 30px;text-align: left;">普元数字化流程应用中心提供完善的
                                                    流程应用门户，整合统一用户中心、 统一认证中心、统一任务中心、组织 机构框架，为用户提供统一的流程操
                                                    作通道、一站式的任务处理、个性化
                                                    的交互感受。</p>
                                            </el-card>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-card shadow="hover" class="card cardBpm">
                                                <div class="imgbox">
                                                    <i class="iconfont icon-shouji"></i>
                                                </div>
                                                <p style="margin-top: 30px;text-align: left;">普元数字化流程应用中心提供完善的
                                                    流程移动客户端，
                                                    支持APP/小程 序/H5三种移动交互形式应用搭建， 方便不同应用场景的用户使用。</p>
                                            </el-card>

                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-card shadow="hover" class="card cardBpm">
                                                <div class="imgbox">
                                                    <i class="iconfont icon-supervision-full"></i>
                                                </div>
                                                <p style="margin-top: 30px;text-align: left;">普元数字化流程应用中心提供支持完
                                                    善的流程监控能力，能够有效采集流 程运转中各类流程数据与采集点业务 数据，并根据客户的需求性质相应的 流程统计分析与监控。
                                                </p>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>
</template>

<script>

import breadcrumb from '@/components/breadcrumb.vue'
export default {
    name: "basicsoftware",
    components: { breadcrumb },
    data() {
        return {
            activeName: 'first',
            scroll: false,
            productDatas: [
                { title: '数字化平台运营体系，支持数字化应用持续演进', desc: '从产品运营视角出发构建数字化平台运营体系，评判开发、应用、业务、企业等多维度的效果指标，形成效能分析，支持数字化应用持续演进' },
                { title: '沉淀可复用的组件库，敏捷拓展企业级应用场景', desc: '简单易用的应用分享和复用功能，支持平台数据模型、组件、数据标准、模块和应用的导出复用，并形成应用/模板市场，帮助企业实现组件的可配置能力和组件的产品化运营，提升和改进低代码平台的整体能效，拓展企业级应用场景' },
                { title: '一次开发多端适配，支持应用的快速传播和复用', desc: '实现一次开发，多端（PC、移动端）适配，以及零代码完成应用移动化，帮助银行大量降低开发成本、维护成本，以高复用、高效率支持业务创新应用在不同终端的快速传播和复用' },
                { title: '促进数据模型驱动业务，规避数据孤岛', desc: '支持跨数据源共享、数据集成等，通过服务总线和网关等集成打通，促进数据模型驱动业务，规避数字化应用规模扩大过程中产生互相割裂的应用数据的“信息孤岛”' },
                { title: '需求结构化驱动，基于业务对象提供开发能力', desc: '在需求结构化的驱动下，从业务建模开始统一业务与技术语义的建设，将底层技术实现抽象为业务对象，提供业务人员能看懂的应用开发能力' },
                { title: '根据业务特点抽象出多类型的业务对象实现金融多法人结构下的多维组织管理', desc: '平台将组织架构中动态的项目组和关系，根据业务特点进行重新抽取调整，帮助企业抽象出用户、账户、产品等多类型的业务对象，建立统一兼顾个性化的多维组织，以及灵活支撑成本、项目管理、运营等多维角色的权限管理模式' },
                { title: '统一数据模型，支撑应用数据的无缝融合', desc: '融合金融企业在元数据、主数据等方面的数据资产，将统一的数据标准规范和数据安全规范固化在平台中，实现海量低代码应用数据的安全、规范使用，并为跨应用数据的融合提供便利，支撑无缝融合' },
                { title: '支持云化部署，以SaaS服务的方式提供低代码能力', desc: '平台支持单应用独立部署与云化部署。底层可以采用分布式架构进行云化部署，满足资源隔离、动态扩容等云化技术要求，以SaaS模式为数字化应用的快速创新提供低代码能力' },
            ],
            specificDatas: [
                { title: '高性能', icon: 'icon-gaojipeizhicopy', desc: '采用多进程管理，SEDA、NIO等成熟技术，同时不依赖于Java EE容器，在系统架构上分散了性能消耗的瓶颈，从而在本质上提高了系统的处理效率。' },
                { title: '高可靠', icon: 'icon-anquanfuben', desc: 'PrimetonESB采用SEDA、NIO等业界先进的技术以及松散的集群部署方式来保障ESB整体基础设施以及关键服务的可靠性，同时当QoS出现异常的时候，还可以通过更加必要的实时方式通知关键人员，从而在管理流程上保障了系统宕机时间的减少。' },
                { title: '高扩展', icon: 'icon-tubiaozhizuomoban-115', desc: 'PrimetonESB产品在设计之初就有针对地在技术、业务、产业环境的特点的基础上重点提升了产品的扩展性，提供了有针对性的、开放的API接口，使得ESB产品更加容易和企业内部现有的系统有机的融合在一起。' },
                { title: '个性化', icon: 'icon-shouzhi', desc: 'PrimetonESB在大客户平台定制服务方面，积累了丰富的技术和管理经验。并成立了专门的部门集中管理、实施和维护定制项目。' },
                { title: '业务化', icon: 'icon-draft-fill', desc: 'PrimetonESB提供非常详细的服务调用轨迹信息，丰富的QoS质量指标， 完备的日志信息和方便的进程管理机制，同时还可以依托服务运行的轨迹 信息形成跨部门的业务流程的监控。' },
            ],
            valueDatas: [
                { title: '基础设施管控能力', icon: 'icon-kucunguanli', desc: '提供了强大的服务监控能力使服务的管理更加业务化、可视化；通过总线形式有效改善现有系统之间调用的网状关系' },
                { title: '轻量级、高性能', icon: 'icon-gaojipeizhicopy', desc: '轻量级的体系架构，并且针对国内实际情况对产品进行了集中优化，使客户只要以较低的投入就可以获得理想的性能要求。' },
                { title: '第三方仲裁能力', icon: 'icon-yuangongjingyingfenxi', desc: '各方系统的对外集成行为都会被ESB服务中介所跟踪和审计；让客户拥有更多的协调和管理能力。' },
                { title: '业务化、适国情', icon: 'icon-shengchanjihualeixing', desc: '目的性、关联性更强的整合信息；管理驾驶舱，展现一些典型的、关键的业务和管理数据；不断扩展的决策支持组件。' },
                { title: '企业流程治理能力', icon: 'icon-zuzhiguanli', desc: '规范的管理框架将更有效地实现IT与业务可视性和管控性。并进一步通过策略的机制(Policy Framework)不断实现IT治理和业务优化。' },
                { title: '高开放、 易扩展', icon: 'icon-guoji', desc: 'PrimetonES B天生就具有很高的开放性和扩展性，包含协议的扩展、统计分析的扩展等，合作伙伴可以依托对外接口完成客户个性化的要求。' },
            ],
            productTags: [
                { icon: 'icon-zhuzhuangtu1', title: '多行业、多类型模型管理' },
                { icon: 'icon-bianmaguize1', title: '丰富的编码能力' },
                { icon: 'icon-navwuliuzhongxin', title: '主数据全生命周期管理' },
                { icon: 'icon-gaojipeizhicopy', title: '服务自动化' },
                { icon: 'icon-ziyuan', title: '完全的数据校验' },
                { icon: 'icon-guoji', title: '国际化数据平台' },
                { icon: 'icon-shouzhi', title: '全场景的数据交付能力' },
            ]
        };
    },
    mounted() {
        var str = document.getElementById("tabsScroll");
        window.addEventListener("scroll", () => {
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            this.scroll = scrollTop > str.offsetTop;
        });
    },
    methods: {
        handleClick(tab, event) {
            var el = document.getElementById('tabsScroll');
            window.scroll({
                top: el.offsetTop - 50,
                behavior: "smooth" // 平滑过渡
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.basicsoftware {
    height: 100%;

    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/productlines/productlines4.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }
    }

    //产品线
    .productlines {

        /* 低代码开发平台 */
        .lowCode {

            h2 {
                font-size: 24px;
                color: #04aaeb;
                text-align: center;

                letter-spacing: 5px;

                margin: 30px auto;
            }

            .overview {
                .title {

                    background: #d3dce6;

                    .container_title {

                        margin: 0 auto;
                        height: 60px;
                        line-height: 60px;
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                    }
                }

                .pro_title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    text-align: center;
                    margin-bottom: 30px;

                    span {
                        display: block;
                    }

                    .chName {
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                        margin-bottom: 20px;
                    }
                }

                .specific {
                    .card {

                        text-align: center;
                        width: 100%;
                        margin-bottom: 20px;
                        transition: all .3s ease-in-out;
                        padding: 20px 0;
                        display: flex;
                        justify-content: start;
                        min-height: 150px;
                        /*    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1); */

                        .imgbox {
                            width: 5px;
                            height: 100px;
                            background: #04aaeb;
                            box-shadow: 0px 0px 5px #04aaeb;

                        }

                        .textdesc {
                            padding-left: 30px !important;

                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin-bottom: 10px;
                                margin-top: 0;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                text-align: left;

                            }
                        }


                    }

                    .mainData {
                        min-height: 150px;
                    }

                    .esb {
                        min-height: 100px;
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);

                        transform: translate(0, -5px);
                    }
                }

                .masterdataSpecific {
                    .card {
                        text-align: center;
                        width: 100%;
                        margin-bottom: 40px;
                        transition: all .3s ease-in-out;
                        padding: 20px 0;

                        .imgbox {
                            height: 80px;
                            line-height: 80px;

                            .iconfont {
                                font-size: 60px;
                                color: #04aaeb;
                            }

                        }

                        .textdesc {
                            padding-left: 30px !important;

                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin-bottom: 10px;
                                margin-top: 0;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                text-align: left;

                            }
                        }


                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .esbSpecific {
                    .type_wrap {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        .card {
                            min-height: 300px;
                            text-align: center;

                            .imgbox {
                                width: 70px;
                                height: 70px;
                                margin: 0 auto;
                                border-radius: 50%;
                                border: 1px solid #04aaeb;
                                text-align: center;
                                line-height: 70px;

                                img {
                                    width: 50%;
                                    display: inline-block;
                                    vertical-align: middle;
                                    transition: All 0.4s ease-in-out;
                                    -webkit-transition: All 0.4s ease-in-out;
                                    -moz-transition: All 0.4s ease-in-out;
                                    -o-transition: All 0.4s ease-in-out;
                                }

                                i {
                                    font-size: 40px;
                                    color: #04aaeb;
                                }
                            }

                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin: 30px auto 10px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }

                        .cardBpm {
                            min-height: 240px;
                        }

                        .card:hover {
                            -webkit-transform: translate(0, -10px);
                            transform: translate(0, -10px);

                            /*        img {
                                animation: rotate 1s forwards;
                                -webkit-animation: rotate 1s forwards;
                            }
 */
                            .imgbox {
                                border: none;
                                box-shadow: 0px 1px 12px -2px #04aaeb;

                            }
                        }
                    }

                }

                .productValue {
                    .card {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        height: 150px;
                        margin-bottom: 20px;
                        padding: 20px;
                        box-shadow: 0px 5px 25px 0px rgba(195, 195, 195, 0.2);
                        -webkit-transition: all 0.5s linear;
                        transition: all 0.5s linear;

                        .imgbox {

                            height: 50px;
                            width: 50px;
                            margin-right: 20px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                transition: all 0.6s;
                            }

                            i {
                                font-size: 40px;
                                color: #04aaeb;
                            }
                        }



                        /*    .personalInfo {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
 */
                        .contbox {
                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin: 0 0 15px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                color: #303133;
                            }


                        }

                    }

                    .card:hover {
                        transform: translate(0, -5px);
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);

                        .imgbox img {
                            transform: scale(1.2);
                        }
                    }
                }

                .keyValue {
                    .card {
                        margin-bottom: 20px;
                        transition: all .3s ease-in-out;
                        padding: 20px;

                        h3 {
                            color: #333;
                            font-size: 16px;

                            margin-bottom: 20px;
                            display: flex;

                            .iconfont {
                                font-size: 26px;
                                color: #04aaeb;
                                margin-right: 20px;

                                display: block;
                            }
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            text-align: left;

                        }
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .imgDesc {
                    text-align: center;

                    img {
                        width: 80%;
                    }
                }

                .overviewdesc {
                    margin-bottom: 50px;
                }

            }


        }

        /* 主数据管理平台 */


    }


}
</style>
