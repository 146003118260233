<template>
    <div class="aboutUs">
        <div class="banner">
            <div class="textbox" style="margin-bottom: 10%">
                <h2 class="animate__animated animate__slideInLeft">新闻中心</h2>
                <h3 class="animate__animated animate__slideInRight">NEWS</h3>
            </div>
        </div>
        <!-- 热点新闻 -->
        <div class="container">
            <div class="aboutUscon padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">热点新闻</span>
                    <span
                        class="enName">
                      H O T
                    </span>
                </div>
              <div class="container">
                <div class="news padUpDown animate__animated animate__fadeInLeft">
                  <div class="contents">
                    <el-carousel indicator-position="none" height="390px">
                      <el-carousel-item v-for="(item, index) in valueNews" :key="index">
                        <el-row :gutter="20" type="flex" justify="space-around" align="middle">
                          <el-col :xs="24" :sm="10" class="imgbox">
                            <img :class="`newsImg${index}`"
                                 :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                          </el-col>
                          <el-col :xs="24" :sm="12">
                            <div class="newCon">
                              <h3>
                                <span>
                                  {{ item.imgName }}<br>
                                  {{item.imgTitle}}
                                </span>
                              </h3>
                              <div class="textDesc">
                                <p>
                                  <div v-for="(segment, index) in item.imgText" :key="index">
                                    <p>{{ segment }}</p>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </el-col>
                        </el-row>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="aboutUscon padUpDown animate__animated animate__fadeInUp" id="divID">
                <div class="pro_title">
                    <span class="chName">新闻中心</span>
                    <span class="enName">N E W S</span>
                </div>
              <el-tabs v-model="activeName" @tab-click="handleClick" >
                <el-tab-pane label="全部" name="" v-loading="paneLoading">
                  <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in valueDatas" :key="index">
                      <el-card shadow="hover" class="card"  >
                        <div @click="goToDtails(item)">
                          <div class="imgbox">
                            <img class="fixed-height" alt="" :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                          </div>
                          <div class="textbox">
                            <h3>{{item.imgName }} {{ item.imgTitle == null?'':item.imgTitle}}</h3>
                          </div>
                          <p  class="text-clamp">{{ item.imgText }}</p>
                        </div>

                      </el-card>
                    </el-col>
                  </el-row>

                </el-tab-pane>
                <el-tab-pane v-for="(item,index) in newsTabs" :label="item.dictLabel" :name="item.dictValue" v-loading="paneLoading">
                  <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in valueDatas" :key="index">
                      <el-card shadow="hover" class="card">
                        <div  @click="goToDtails(item)">
                          <div class="imgbox">
                            <img class="fixed-height" alt="" :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                          </div>
                          <div class="textbox">
                            <h3>{{ item.imgName }}</h3>
                          </div>
                          <p  class="text-clamp">{{ item.imgText }}</p>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
              <el-pagination
                  v-show="total > 0"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="queryParams.params.pageNum"
                  :page-size="queryParams.params.pageSize"
                  layout="total, prev, pager, next"
                  :total="total">
              </el-pagination>
            </div>
        </div>
      <div class="subban">
        <div class="container_title">
          <div class="con">
            <h1 class="chName">数智化 ...</h1>
            <span class="enName">交给我们 项目闭环 确保落地</span>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
</template>

<script>
import {selectXWZXImagesListAnoy, selectXWZXImagesListIsShow, selectXWZXImagesListTOP3Anoy} from "@/api/collaborators";
import {getDicts} from "@/api/dict";
export default {
    name: "newscenter",
  data() {
        return {
          valueNews:[],
          paneLoading:false,
          // 查询参数
          queryParams: {
            params: {
              pageNum: 1,
              pageSize: 9
            },
            imgItemType: ''

          },
          // 总条数
          total: 0,
          activeName: '',
          valueDatas: [],
          newsTabs:[],
          bannerDatas: [
            { title: '十五年甲方实战', text: '精准切入变革痛点 更有实力助推转型' },
            { title: '软件产品', text: '全栈式信创能力助力企业关键业务系统平滑迁移' },
            { title: '打造智慧工厂', text: '制造数字化-丰富实战与落地能力' },
            { title: '内外协同数字化办公', text: '统一门户 所有工作集成串连' }
          ],
        };
    },
    created() {

    },
  mounted() {
    getDicts('yzsk_web_news_tabs').then(response => {
      this.newsTabs = response.data
    })
    if (this.$route.query.activeName == undefined){
      this.getXWZXImagesList()
    }else {
      this.activeName = this.$route.query.activeName
      this.queryParams.imgItemType = this.$route.query.activeName
      this.getXWZXImagesList(this.queryParams)
      window.scrollTo(0, 1210);
    }

      //获取路由参数
      // this.activeName = this.$route.query.activeName
      // let tab = {
      //   name: this.activeName
      // }
      // if (this.activeName != null && this.activeName != ''){
      //   this.handleClick(tab,event)
      // }
    this.getXWZXImagesListTOP3()
  },
  methods: {
    getXWZXImagesListTOP3() {
      selectXWZXImagesListIsShow().then(response => {
        if (response.rows){
          this.valueNews = response.rows.map(item => {
            item.imgText = item.imgText.split('。').map(segment => segment.trim());
            return item;
          });
        }
      })
    },
      /** 点击跳转详情*/
      goToDtails(item) {
          this.$router.push({
            name: 'newscenterDetails',
            query: {
              id: item.id
            }
          })
      },
      /** 分页*/
      handleSizeChange(val) {
        this.queryParams.params.pageSize = val
        this.getXWZXImagesList(this.queryParams)
      },
      handleCurrentChange(val) {
        this.queryParams.params.pageNum = val
        this.getXWZXImagesList(this.queryParams)
      },
      /** 分页结束*/
      /** 获取合作伙伴的图片信息*/
      getXWZXImagesList() {
        this.paneLoading = true
        selectXWZXImagesListAnoy(this.queryParams).then(response => {
          if (response.rows){
            this.valueDatas = response.rows
            this.total = response.total
            this.paneLoading = false
          }
        })
      },
      /** 点击跳转页签*/
      handleClick(tab, event) {
        this.queryParams.imgItemType = tab.name
        this.getXWZXImagesList(this.queryParams)
      },
    },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  /* //圆周资讯 */
  .news {
    .contents {
      padding: 20px;
      background: #f7f9fb;

      .imgbox {
        height: 360px;
        display: flex;
        align-items: center;

        img {
          border-radius: 20px;
          margin: 0 auto;
          max-width: 100%;
        }
      }

      .newCon {
        h3 {
          color: #333;
          font-size: 24px;
          margin: 20px 0;
          text-align: center;
          line-height: 36px;
        }

        .textDesc {
          /*   max-height: 250px;
          overflow-y: auto; */

          p {
            text-indent: 2em;
            font-size: 14px;
            line-height: 25px;
            color: #333;
          }
        }


      }

      .el-carousel__item {
        background-color: unset !important;
      }
    }

  }
  .subban {
    width: 100%;
    height: 400px;
    position: relative;
    background: #000;
    color: #fff;
    line-height: 60px;
    background: url("@/assets/images/index/intelligence.jpg") no-repeat center center;
    background-size: cover;

    .con {
      position: absolute;
      bottom: 100px;
      font-size: 38px;

      .chName {
        font-size: 60px;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
  }
  .bannerImg {
    .bannerImg {
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 10px;
      }

      .textbox {
        position: absolute;
        top: 30%;
        left: 10%;

        h2 {
          font-size: 50px;
          color: #04aaeb;
        }

        h3 {
          font-size: 30px;
          color: #fff;
        }
      }
      /* 小屏幕（例如手机）的样式 */
      @media (max-width: 768px) {
        .textbox {
          top: 20%;
          left: 5%;
        }

        .textbox h2 {
          font-size: 30px;
        }

        .textbox h3 {
          font-size: 20px;
        }
      }
    }
  }
    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/newscenter/news.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }

        h3 {
            margin-top: 20px;
            font-size: 40px;
            color: #fff;
            letter-spacing: 20px;
        }

    }

    //产品线
    .aboutUscon {
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 15px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin: 20px 0;
                letter-spacing: 5px;
            }
        }

        .card {
            /* height: 430px; */
            min-height: 430px;
            text-align: center;
            -webkit-transition: all 0.5s linear;
            transition: all 0.5s linear;

            .imgbox {

                width: 100%;
                /*   height: 240px; */
                overflow: hidden;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                    transition: All 0.4s ease-in-out;
                    -webkit-transition: All 0.4s ease-in-out;
                    -moz-transition: All 0.4s ease-in-out;
                    -o-transition: All 0.4s ease-in-out;
                }
              .fixed-height {
                height:200px; /* 固定高度 */
                width: auto;   /* 宽度按比例缩放 */
                object-fit: contain; /* 按比例缩放并覆盖容器 */
              }
            }

            .textbox {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0 0;

                h3 {
                    color: #04aaeb;
                    font-size: 16px;
                }
            }

            p {
                font-size: 12px;
                line-height: 20px;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* 显示的行数 */
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
        }

        .card:hover {
            -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);

            img {
                transform: scale(1.2);

            }
        }
    }

    .history {
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 60px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;
            }

            .enName {
                width: 70%;
                margin: 0 auto;
            }
        }

        .type_wrap {
            .bossInfo {
                h2 {
                    text-align: center;
                    font-size: 20px;
                }

                h3 {
                    text-align: center;
                    font-size: 16px;
                    margin: 15px 0;
                }

                ul {
                    padding: 30px 0;

                    li {
                        font-size: 14px;
                        line-height: 30px;
                    }
                }
            }

            .type_item {
                .imgbox {
                    max-width: 300px;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        display: block;
                        border-radius: 10px;
                    }
                }

                .card {
                    margin-top: 30px;
                    -webkit-transition: all 0.5s linear;
                    transition: all 0.5s linear;



                    .textbox {
                        margin-bottom: 10px;
                        display: flex;

                        .iconfont {
                            color: red;
                            font-size: 20px;
                        }

                        h3 {
                            color: #333;
                            font-size: 16px;
                            margin-left: 10px;
                        }
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 30px;
                    }
                }

                /*  .card:hover {
                    -webkit-transform: translate(0, -10px);
                    transform: translate(0, -10px);

                    img {
                        animation: rotate 1s forwards;
                        -webkit-animation: rotate 1s forwards;
                    }
                } */
            }

        }
    }

    /* 解决方案 */
    .cultures {

        .pro_title {
            font-size: 16px;
            color: #727272;
            text-align: center;
            margin-bottom: 60px;
            letter-spacing: 15px;
            font-weight: 600;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;

            }
        }

        .type_wrap {
            margin-bottom: 30px;
            min-height: 120px;

            .imgbox {
                overflow: hidden;
                height: 200px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    transition: all 0.6s;
                }
            }

            .contbox {
                padding-top: 50px;
                max-height: 200px;

                h3 {
                    color: #333;
                    font-size: 20px;
                    margin: 0 0 20px;

                    span {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }


            }
        }

        .type_wrap:hover {
            .imgbox img {
                /*  animation: rotate 1s forwards;
      -webkit-animation: rotate 1s forwards; */
                transform: scale(1.2);
            }
        }
    }

    .officeEnvironment {
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 60px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;
            }
        }

        .type_wrap {

            .type_item {
                margin-bottom: 20px;

                .card {

                    text-align: center;
                    -webkit-transition: all 0.5s linear;
                    transition: all 0.5s linear;

                    .offerbox {
                        width: 100%;
                        height: 400px;
                        overflow: hidden;
                        box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.4);

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            transition: All 0.4s ease-in-out;
                            -webkit-transition: All 0.4s ease-in-out;
                            -moz-transition: All 0.4s ease-in-out;
                            -o-transition: All 0.4s ease-in-out;
                        }
                    }

                    .offerbox1 {
                        height: unset;
                    }

                    .imgbox {
                        width: 60px;
                        height: 60px;
                        margin: 0 auto;
                        border-radius: 50%;
                        box-shadow: 0px 4px 11px -3px #04aaeb;
                        text-align: center;
                        line-height: 60px;

                        img {
                            width: 50%;
                            display: inline-block;
                            vertical-align: middle;
                            transition: All 0.4s ease-in-out;
                            -webkit-transition: All 0.4s ease-in-out;
                            -moz-transition: All 0.4s ease-in-out;
                            -o-transition: All 0.4s ease-in-out;
                        }

                        .iconfont {
                            font-size: 26px;
                        }
                    }

                    h3 {
                        color: #333;
                        font-size: 16px;
                        margin: 15px auto 10px;
                    }
                }

                .card:hover {
                    img {
                        transform: scale(1.2);
                    }

                    .imgbox {
                        background-color: #04aaeb;
                        transition: All 0.4s ease-in-out;

                        .iconfont {
                            color: #fff;

                        }
                    }

                }
            }

        }
    }

    .joinUs {
        position: relative;

        /*  background: #000;
        color: #fff; */
        /*      background: url("@/assets/images/aboutus/joinus2.jpg") no-repeat 100%; */
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 40px;

            .chName {
                font-size: 26px;
                font-weight: bolder;
                margin-bottom: 20px;
                letter-spacing: 5px;
                display: block;
            }

            .enName {

                display: block;
                font-style: italic;
                color: red;
                cursor: pointer;
            }
        }

        p {
            line-height: 40px;
            letter-spacing: 2px;
            text-indent: 2em;
        }
    }


    .contactUsCon {
        padding: 0 50px 50px;

        .pro_title {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 30px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
            }
        }

        .textDesc {
            h2 {
                text-align: center;
            }
            .caveat {
                margin-bottom: 20px;
                border: 1px solid #f5f5f5;
                padding: 10px 20px;

                i {
                    margin-right: 5px;
                    font-size: 20px;
                    color: #04aaeb;
                }

                h3 {
                    font-size: 15px;
                    margin-bottom: 10px;
                    color: #000;
                }

                p {
                    line-height: 30px;
                    font-size: 14px;
                    color: #000;

                    a {
                        text-decoration: none;
                        color: #000;
                    }

                    a:hover {
                        color: #e6a23c;
                    }
                }
            }

        }
    }
}
</style>
