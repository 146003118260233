const math = require('mathjs');

export function performOperation(operation, arg1, arg2, decimalPlaces) {//类型，值1，值2，取几位小数
  const arg1Value = typeof arg1 === 'string' ? parseFloat(arg1) : arg1;//传入字符串类型进行转换
  const arg2Value = typeof arg2 === 'string' ? parseFloat(arg2) : arg2;
  let result;
  switch (operation) {
    case 'add'://加
      result = math.add(math.bignumber(arg1Value), math.bignumber(arg2Value));
      break;
    case 'subtract'://减
      result = math.subtract(math.bignumber(arg1Value), math.bignumber(arg2Value));
      break;
    case 'multiply'://乘
      result = math.multiply(math.bignumber(arg1Value), math.bignumber(arg2Value));
      break;
    case 'divide'://除
      if (arg2Value === 0) {
        throw new Error("不允许除零");
      }
      result = math.divide(math.bignumber(arg1Value), math.bignumber(arg2Value));
      break;
    default:
      throw new Error("无效的操作。支持的操作有“加”、“减”、“乘”和“除”。");
  }
  return Number(math.format(result, { notation: 'fixed', precision: decimalPlaces }));
}

export  function add(arg1, arg2, decimalPlaces) {
  return performOperation('add', arg1, arg2, decimalPlaces)
}

export function subtract(arg1, arg2, decimalPlaces) {
  return performOperation('subtract', arg1, arg2, decimalPlaces)
}

export function multiply(arg1, arg2, decimalPlaces) {
  return performOperation('multiply', arg1, arg2, decimalPlaces)
}

export function divide(arg1, arg2, decimalPlaces) {
  return performOperation('divide', arg1, arg2, decimalPlaces)
}


//金额千分位与两位小数格式
export function formatNumber(arg1,precision) {
  const number = typeof arg1 === 'string' ? parseFloat(arg1) : arg1;//传入字符串类型进行转换

  if (number || number===0) {
    let result = math.format(number, { notation: 'fixed', precision: precision });


    // 将数字转换为字符串并按照小数点进行拆分
    let parts = result.toString().split(".");
    // 获取整数部分
    let integerPart = parts[0];
    // 获取小数部分
    let decimalPart = parts.length > 1 ? "." + parts[1] : "";

    // 对整数部分添加千分位分隔符，并返回结果
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimalPart;
   // return  result.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
   //  return result.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  }
}

//金额千分位与两位小数格式
export function moneyFormatter(row, column) {
  if (row[column.property] || row[column.property]===0) {
    return formatNumber( row[column.property],4);
  }
}

//数量千分位与两位小数格式
export function numFormatter(row, column) {
  if (row[column.property] || row[column.property]===0) {
    return formatNumber( row[column.property], 0);
  }
}
